import a1 from "./1.jpg";
import a2 from "./2.jpg";
import a3 from "./3.jpg";
import a4 from "./4.jpg";
import a5 from "./5.jpg";
import a6 from "./6.jpg";
import a7 from "./7.jpg";
import a8 from "./8.jpg";
import a9 from "./9.jpg";
import a10 from "./10.jpg";
import a11 from "./11.jpg";
import a12 from "./12.jpg";

export default {
  carName: "Jeep Patriot",
  capacity: "2.5",
  carYear: "2016",
  engineType: "GAS",
  odo: "66000",
  drive: "FWD",
  price: "12900",
  averagePrice: "14200",
  hit: true,
  sold: true,
  photos: [a1, a2, a3, a4, a5, a6, a7, a8, a9, a10, a11, a12]
};
